(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/modals');

require('./modules/forms');

require('./modules/masks');

require('./modules/spinner');

require('./modules/scroll');

require('./modules/menus');

require('./modules/tabs');

require('./modules/galleries');

require('./modules/sliders');

require('./modules/card-choose-offer');

// import InstagramWidget from './modules/widgets/instagram';
// import ClosableTeaser from './modules/widgets/closable-teaser';


// Init instagram widget
// ---------------------

// const instagram = new InstagramWidget({
//   limit: {
//     default: 12,
//     mobile: 4,
//   },
// });


// Init closable teaser
// --------------------

// const teaser = new ClosableTeaser({
//   selector: '.js-floating-teaser',
//   closeSelector: '.js-close-floating-teaser',
//   storageKey: 'TEASER_HIDE_DATE',
//   daysToHide: 7,
// });


// Отслеживаем нажатие на кнопку назад
// -----------
$(function () {
  var hash = window.location.hash;
  setInterval(function () {
    if (window.location.hash != hash) {
      hash = window.location.hash;
      if (hash == '') {
        $('.js-close-popup').trigger('click');
      }
      //alert("User went back or forward to application state represented by " + hash);
    }
  }, 100);
});

// фиксим ширину slick слайдера на Mac Air
// --------------
var $window = $(window);
var $slider = $('.about-brand-section__history-wrapper .slick-slider');
$window.on('resize windowInit', function () {
  var window_width = $window.width();
  $slider.css('width', 'auto');
  $('body').css('width', 'auto');

  var value;
  if (window_width > 480) {
    value = window_width * .7 - 70;
  } else {
    value = window_width - 40;
  }
  $slider.width(value);
}).trigger('windowInit');

},{"./modules/card-choose-offer":3,"./modules/forms":4,"./modules/galleries":5,"./modules/masks":8,"./modules/menus":9,"./modules/modals":10,"./modules/scroll":11,"./modules/sliders":12,"./modules/spinner":13,"./modules/tabs":14}],2:[function(require,module,exports){
module.exports={
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1440
  }
}

},{}],3:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./vue-components/PriceComponent');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./vue-components/OffersListComponent');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */
window.initPriceApp = function () {
  if (!$('#js-card-price-app').length) return;

  var cardPriceApp = new Vue({
    el: '#js-card-price-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default
    },
    data: {
      offer: {},
      targetButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {
        var data = $(this.targetButton).data();
        $.extend(data, val.basketData);
        $(this.targetButton).data(data);
      }
    }
  });
};

window.initPriceApp();

},{"./vue-components/OffersListComponent":15,"./vue-components/PriceComponent":16}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  console.log(event);
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

},{"./globals":6}],5:[function(require,module,exports){
"use strict";

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfig = require('../media-queries-config.json');

var _mediaQueriesConfig2 = _interopRequireDefault(_mediaQueriesConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

},{"../media-queries-config.json":2}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals":6}],8:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

(function () {

  $('input[type="tel"]').mask('+7 (999) 999-99-99', {
    autoclear: false
  });

  if (_globals.IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }
})();

},{"./globals":6}],9:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var $menus = $('.js-menu');

if (_globals.IS_DESKTOP) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 500));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_MOBILE) {
  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (is_hovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    }

    $('body').removeClass('menu-is-opened');
  });
}

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  var opened_class = 'menu-is-opened';

  _globals.$BODY.toggleClass(opened_class);

  if (_globals.$BODY.hasClass(opened_class)) {
    _globals.$BODY.on('click.close-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.header__menu').length || $target.hasClass('js-toggle-mobile-menu')) {
        e.stopPropagation();
        return false;
      }

      _globals.$BODY.removeClass(opened_class).off('click.close-menu');
    });
  } else {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');
  }
});

$('.js-toggle-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  $(this).closest('.has-dropdown').toggleClass('is-hovered');
});

$('.js-mobile-search .btn').click(function () {
  $(this).closest('form').submit();
});

},{"./globals":6}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var popup = $(this).attr('href');
  var delay = 0;

  var $parentPopup = $(this).closest('.popup');
  if ($parentPopup.length) {
    $.overlayLoader(false, $parentPopup);
    delay = 350;
  }

  setTimeout(function () {
    $.overlayLoader(true, {
      node: popup,
      hideSelector: '.js-close-popup',
      fixedElements: '.js-fixed-element',
      onShow: function onShow() {
        if (history.pushState) {
          window.history.pushState(null, null, popup);
        }
      },
      onHide: function onHide() {
        if (history.replaceState) {
          window.history.replaceState(null, null, ' ');
        }
      }
    });
  }, delay);
});

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Popup initializaation by url-hashes
$(function () {
  $(window).on('hashchange initialLoading', function () {
    initPopupByHash();
  }).trigger('initialLoading');
});

function initPopupByHash() {
  var hash = document.location.hash;
  var $element = $(hash);

  if ($element.length && $element.hasClass('popup')) {
    var $link = $('a[href="' + hash + '"]').first();
    _globals.$WINDOW.scrollTo($link.closest('section'));
    $link.click();
  }
}

},{"./globals":6}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $this = $(this);
  var link = $this.attr('href');
  var $elemToScroll = $('#' + link.split('#')[1]);
  var speed = $this.data('speed') || 150;
  var offset = $this.data('offset') || 0;

  if ($elemToScroll.length) {
    _globals.$WINDOW.scrollTo($elemToScroll, { duration: speed, offset: offset });
  } else {
    document.location = link;
  }
});

// Scrolling to top
// ----------------

// if (!IS_MOBILE_WIDTH()) {
//   const $go_top_btn = $('<div class="go-top-btn"></div>');

//   $go_top_btn.click(() => {
//     $WINDOW.scrollTo(0, 200);
//   });

//   $WINDOW.scroll(() => {
//     const scroll_top = $WINDOW.scrollTop();
//     if (scroll_top > 0) {
//       $go_top_btn.addClass('is-visible');
//     } else {
//       $go_top_btn.removeClass('is-visible');
//     }
//   });

//   $BODY.append($go_top_btn);
// }


// Promocode link pinning
// ----------------------

_globals.$WINDOW.on('resize.promocodePin promocodePinInit', function () {
  pinPromocodeLink();
}).trigger('promocodePinInit');

function pinPromocodeLink() {
  var $promocodeLink = $('.promocode-popup-link');
  var $footer = $('.footer');

  _globals.$WINDOW.off('scroll.promocodePin').on('scroll.promocodePin', function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    var windowHeight = _globals.$WINDOW.height();
    var footerPosition = $footer.offset().top;
    var distanceModifier = _globals.$WINDOW.width() <= _globals.TABLET_WIDTH ? 0 : 320; // 320 - тупо сдвиг, подобранный, чтобы попадало между обратным звонком и соцсетями
    var delta = scrollTop + windowHeight - distanceModifier - footerPosition;

    if (delta > 0) {
      $promocodeLink.css('marginBottom', delta);
    } else {
      $promocodeLink.css('marginBottom', 'auto');
    }
  });
}

// Inview
// ------

$('.js-inview').each(function (index) {
  var _this = this;

  $(this).bind('inview', function (event, isInView, visiblePartX, visiblePartY) {
    if (isInView) {
      $(_this).addClass('inview'); //.unbind('inview');
    } else {
      $(_this).removeClass('inview');
    }
    $(window).trigger('inviewTriggered');
  });
});

$(window).on('inviewTriggered.progress', function () {
  var $sections = $('.js-inview');
  var $activeSection = $('.js-inview.inview:last');
  var link = '#' + $activeSection.attr('id');
  $('.header__menu li.is-active').removeClass('is-active');
  $('.header__menu').find('a[href="' + link + '"]').closest('li').addClass('is-active');
});

},{"./globals":6,"./helpers":7}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

$('.js-slideshow').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
});

$('.js-vitrine-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$('.js-gallery-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }]
  });
});

$('.js-history-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
});

},{"./globals":6}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals":6}],14:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function () {
    $(this).find('.tabs-nav li:first a').click();
  });
});

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = PriceComponent;

},{}]},{},[1]);
